<template>
    <div class="reg-box">

        <reg-tips v-if="showRegTips"/>

        <div class="reg-title">
            <div class="reg-ch-title">注册和授权</div>
            <div class="reg-en-title">Registration and Authorization</div>
        </div>
        <div>
            <div class="ast-reg-steps">
                <div class="ast-reg-step active">1</div>
                <div class="ast-reg-step">2</div>
                <div class="ast-reg-step">3</div>
                <div class="ast-reg-step">4</div>
                <div class="ast-reg-step">5</div>
            </div>

            <template v-if="step === 1">

                <div class="cm-form">
                    <div class="cm-item">
                        <div class="cmf-title">注册主体企业或个体工商户营业执照</div>
                        <div class="flex flex-left-center">
                            <upload-image
                                    style="width: 200px"
                                    :imageUrl="params.business_license_info.img_url"
                                    :done="busLicUploadDone"
                                    :action="uploadBusLicUrl"
                                    class="pay-apply-upload"
                                    @del=" params.business_license_img = '' "
                            />
                            <div class="tips ml-8">
                                <div>请上传营业执照，需年检章齐全，当年注册除外。</div>
                                <div>格式要求：原件照片、扫描件或者加盖公章的复印件，支持.jpg .jpeg
                                    .png格式照片，大小不超过10M。
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="cm-tw">

                        <div class="cm-item">
                            <div class="cmf-title">
                                <span>主体法人</span>
                                <template v-if="params.business_license_info.legal_representative">
                                    <span>({{ params.business_license_info.legal_representative }})的</span>
                                </template>
                                <span>实名微信号</span>
                            </div>
                            <at-input v-model="params.weixin" class="full-with"/>
                        </div>

                        <div class="cm-item">
                            <div class="cmf-title">注册主体企业法人姓名</div>
                            <at-input v-model="params.business_license_info.legal_representative" class="full-with"/>
                        </div>

                    </div>

                    <div class="cm-item">
                        <div class="cmf-title">注册主体企业名称</div>
                        <at-input v-model="params.business_license_info.enterprise_name" class="full-with"/>
                    </div>
                    <div class="cm-item">
                        <div class="cmf-title">营业执照注册地址</div>
                        <at-input v-model="params.business_license_info.address" class="full-with"/>
                    </div>
                    <div class="cm-item">
                        <div class="cmf-title">注册主体企业社会信用代码</div>
                        <at-input v-model="params.business_license_info.reg_num" class="full-with"/>
                    </div>

                    <div class="confirm-area">
                        <button @click="confirmApply" class="btn">提交</button>
                    </div>

                </div>

            </template>
            <template v-else>
                <div class="cm-form">
                    <a-result style="width: 380px;padding: 0">

                        <template #title>
                            创建小程序申请已推送到法人微信，请法人在手机端完成授权。
                        </template>
                        <template #icon>
                            <a-icon type="smile" theme="twoTone"/>
                        </template>

                        <template #extra>
                            <template v-if="hasError">
                                <span class="text-error">发生错误：{{ statusMsg }}</span>
                                <div class="mt-4">
                                    <a-button type="primary" @click="redo">重新填写</a-button>
                                </div>
                            </template>
                            <template v-else>
                                <span style="margin-bottom: 12px">当前状态：{{ statusMsg }}</span>

                                <div><a @click="showVideoModal = true">操作参考视频</a></div>

                                <div class="mt-4">
                                    <a-button type="primary" @click="redo">重新填写</a-button>
                                </div>

                            </template>
                        </template>
                    </a-result>
                </div>

            </template>


        </div>


        <div>

        </div>


        <a-modal width="350px" title="操作参考视频" v-model="showVideoModal" :footer="null">
            <video controls width="100%" ref="helpVideo" src="https://static.ycsd.work/col/reg/basic.mp4"></video>
        </a-modal>
    </div>
</template>

<script>
import AtInput from "@/components/AtInput.vue";
import api from "@/repo/api";
import UploadImage from "@/components/UploadImage.vue";
import RegTips from "@/views/AST/Reg/Com/RegTips.vue";

export default {
    name: "ChooseType",
    components: {UploadImage, AtInput, RegTips},
    props: {
        companyInfo: Object
    },
    data() {
        return {
            step: 1,
            showVideoModal: false,
            params: {
                weixin: '',
                business_license_info: {}
            },
            hasError: false,
            showRegTips: false,
            timerFlag: null,
            statusMsg: "等待法人完成后续流程。",
        }
    },
    destroyed() {
        if (this.timerFlag) clearTimeout(this.timerFlag)
    },
    mounted() {

        if(!localStorage['is_show_reg_tips']) {
            this.showRegTips = true
            localStorage['is_show_reg_tips'] = 1;
        }

        if (this.companyInfo) {

            if (this.companyInfo.is_submit_reg) this.gotoStep2()

            this.busLicUploadDone(this.companyInfo.business_license_info);
            this.params.weixin = this.companyInfo.legal_persona_wechat || ''
        }
    },
    computed: {
        uploadBusLicUrl() {
            return api.colUrl('/ast-reg/ocr-business-license')
        }
    },
    methods: {
        busLicUploadDone(res) {
            this.params.business_license_info = res || {}
        },
        gotoStep2() {
            this.step = 2;
            this.getApplyStatus(true)
        },
        getApplyStatus(repeat) {
            api.get('/ast-reg/apply', (data) => {

                let cont = this.parseDataStatus(data.data.status, data.data)

                if (repeat && cont) this.timerFlag = setTimeout(() => {
                    this.getApplyStatus(repeat)
                }, 5000)
            })
        },
        parseDataStatus(status, info) {
            if (status === 'none') {

                this.statusMsg = '等待法人完成后续流程';

                return true;
            }

            status = 'a' + status

            if (status === 'a0') {

                this.$message.success('小程序注册成功');

                this.$emit('success')

                return false
            }

            let msgList = {
                a89249: '该appid已有转正任务执行中，距上次任务 24h 后再试',
                a89251: '模板消息已下发，待法人人脸核身校验',
                a89252: '法人&企业信息一致性校验中',
            }

            this.statusMsg = msgList[status] || info['status_msg']

            this.hasError = info.status !== 0

            return false;
        },
        redo() {
            this.statusMsg = ''
            this.hasError = false

            this.step = 1;
        },
        confirmApply() {

            this.$loading.show()

            api.post('/ast-reg/reg-company-app', this.params, (data) => {

                this.$loading.hide();


                if (data.code !== 0) {

                    this.$message.error(data.msg)

                    return
                }

                this.getApplyStatus(true)

                this.step = 2;
            });
        }
    }
}
</script>

<style lang="less" scoped>

.reg-selections {
    margin-top: 72px;

}

.icon-box {
    width: 80px;
    height: 80px;
    background: #F5F5F5 url("/assets/icons/xcx-icon-black.png") no-repeat center;
    background-size: 32px;
    margin-right: 16px;

    &.green {
        background: rgba(6, 222, 107, 0.2) url("/assets/icons/xcx-icon-green.png") no-repeat center;
        background-size: 32px;
    }
}

.reg-selection {
    border: 1px solid #EEEEEE;
    padding: 20px;
    width: 440px;
    margin-bottom: 32px;

    background-image: url("/assets/icons/ast-reg-right.png");
    background-repeat: no-repeat;
    background-size: 74px;
    background-position: 326px 79px;

    display: flex;


    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;

    cursor: pointer;
}

.selection-title {
    margin-top: 12px;
    margin-bottom: 12px;
}

.company-reg {
    margin-top: 72px;
    margin-left: 120px;
}

.company-reg-title {
    width: 177px;
    height: 64px;

    background-image: url("/assets/icons/compang-reg-title.png");
    background-size: 177px;
}

.ast-reg-steps {
    margin-bottom: 32px;
}

.confirm-area {
    margin-bottom: 130px;
}

</style>