<template>
    <div class="pay-container" v-if="show">
        <div class="pay-logo"></div>
        <div class="pay-step-2">
            <div class="pay-header">
                <div class="pay-header-text">因亲身使用，我选择推广颜创，为颜创代言</div>
            </div>
            <div class="pay-2-warpper">
                <div class="pay-2-box">
                    <div class="left">
                        <div class="left-box">
                            <div class="pay-2t1">欢迎部署穿搭私域小程序系统</div>
                            <div class="pay-2t2">
                                <div>请准备好以下资料</div>
                                <div>方便下一步进行小程序注册、认证及政府备案</div>
                            </div>
                            <div class="pay-2t3">
                                <div>① 主体营业照片</div>
                                <div>② 营业执照法人身份证正反面照片</div>
                                <div>③ 公司营业执照准备开户银行账号信息（账号、开户行）
                                    <div>个人营业执照准备个人银行卡信息（卡号、开户行）</div>
                                </div>
                                <div>④ 法人本人需要下一步注册、认证、备案过程中途配合
                                    <div>进行手机端人脸身份核验</div>
                                </div>
                            </div>
                            <div class="pay-2t4">

                                <div @click="gotoReg" class="reg-btn" :class="{disabled: readTime > 0}">
                                    <span>开始注册</span>
                                    <template v-if="readTime > 0 "> ({{ readTime }})</template>
                                </div>

                                <div class="tt1">因各地管理部门审核政策不同，注册开通流程预计需7天~20天</div>

                            </div>
                        </div>
                    </div>
                    <div class="right" v-if="parentInfo">

                        <div class="pt-info">
                            <div class="pt-image">
                                <template v-if="parentInfo.pt_image">
                                    <img :src="parentInfo.pt_image" alt="">
                                </template>
                            </div>
                            <div class="pt-summary">
                                <div class="pt-summary-title">个人介绍</div>
                                <div class="pt-summary-content">{{ parentInfo.pt_summary }}</div>
                            </div>
                        </div>

                        <div class="contact-info">
                            <div class="pt-phone">
                                <div>合伙人：{{ parentInfo.pt_name }}</div>
                                <div>电话：{{ parentInfo.pt_phone }}</div>
                            </div>
                            <div class="pt-qrcode">
                                <div class="pt-qrcode-image">
                                    <template v-if=" parentInfo.pt_qrcode">
                                        <img :src="parentInfo.pt_qrcode" alt="">
                                    </template>
                                </div>
                                <div>微信二维码</div>
                            </div>
                        </div>


                    </div>
                </div>
                <div class="pay-mz">
                    <span class="sp1">我将依靠自身使用经验，帮助你尽快熟悉我们私域系统</span>
                    <span>*根据公司现有政策：必须自身日常使用软件，才能申请成为公司推广合伙人</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/repo/api";

export default {
    data() {
        return {
            step: 0,
            readTime: 30,
            error: false,
            parentInfo: undefined,
            qrcodeBase64: null,
            payInfo: {},
            timerFlag: 0,
            show: true
        }
    },
    mounted() {
        this.init()
    },
    destroyed() {
        clearTimeout(this.timerFlag)
    },
    methods: {
        init() {

            api.get('/ast-reg/parent-info', (data) => {

                if (!data.data) return;

                this.parentInfo = data.data
            })

            this.begin()
        },
        gotoReg() {

            if (this.readTime > 0) return;

            this.show = false
        },
        begin() {
            this.readTime = 30;
            this.step = 2;
            this.countDown()
        },
        countDown() {
            setTimeout(() => {

                this.readTime--;

                if (this.readTime > 0) this.countDown()

            }, 1000)
        }
    }
}
</script>

<style>

html {
    font-size: 24px;
}

@media screen and (min-width: 2560px) {
    html {
        font-size: 36px;
    }
}

@media screen and (max-width: 1900px) {
    html {
        font-size: 26px;
    }
}

@media screen and (max-width: 1700px) {
    html {
        font-size: 18px;
    }
}


@media screen and (max-width: 1200px) {
    html {
        font-size: 16px;
    }
}
</style>

<style scoped lang="scss">

$reg-pay-sub-text-color: rgb(89, 87, 87);
$reg-pay-text-color-1: #757475;

@function prem($w) {
    @return $w/24 + rem;
}


//@function prem($w) {
//    @return $w+ px;
//}
.error {
    font-size: prem(26);
    color: red;
    z-index: 1;
    margin-top: 20%;
}

.pay-background {
    background-image: url("https://static.ycsd.work/collocation-helper/ast/reg-pay-bg.png");
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: fixed;
    left: 0;
    top: 0;
}

.pay-mz {
    font-size: prem(15);
    color: #FFFFFF;
    text-align: center;
    margin-top: prem(56);
    margin-bottom: prem(88);
}

.pay-title {
    font-size: prem(38);
    color: white;
    text-align: center;
    margin-top: prem(184);
}

.pay-logo {
    width: prem(308);
    height: prem(49);
    background-image: url("https://static.ycsd.work/collocation-helper/ast/yc-logo.png");
    background-size: 100%;
    position: fixed;
    top: prem(52);
    left: prem(80);
    z-index: 2;
}

.pay-container {
    color: $reg-pay-sub-text-color;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 2222;
}

.pay-wrapper {
    width: prem(1445);
    margin: 0 auto;
    position: relative;
    padding: 1px;
}

.pay-box {
    height: prem(611);
    background-color: rgba(255, 255, 255, .7);
    border-radius: prem(19.1);
    margin-top: prem(45);
    position: relative;

    display: flex;

    > div {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.pay-box-line {
    width: 2px;
    height: prem(397);
    position: absolute;
    left: 50%;
    margin-left: -1px;
    background-color: $reg-pay-sub-text-color;
    top: prem(112);
    opacity: .6;
}

.pay-qrcode {
    width: prem(275);
    height: prem(275);
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;

    img {
        width: 100%;
        height: 100%;
    }
}

.pay-price {
    font-size: prem(17);
    line-height: prem(17);

    .price {
        font-size: prem(32);
        line-height: prem(32);
    }
}

.hm-1 {
    margin-top: prem(43);
}

.hm-2 {
    margin-top: prem(22);

}

.pt-title {
    font-weight: bold;
    font-size: prem(17);
    width: prem(200);
    text-align: left;
}

.pt-ol {
    padding-left: prem(16);
    margin-top: prem(38);

    li {
        margin-top: prem(22);
        font-size: prem(17);
    }
}

.pay-header {
    width: 100%;
    height: prem(213);
    background-image: url("https://static.ycsd.work/collocation-helper/ast/pay-2-header.png");
    background-size: 100% 100%;
    background-position: center;
    position: relative;
}

.pay-step-2 {
    max-width: 100%;
}

.pay-2-warpper {
    width: prem(1540);
    max-width: 100%;
    margin: 0 auto;
    padding: 1px;
    background-color: #FFFFFF;

    .pay-mz {
        color: $reg-pay-text-color-1;
        margin-top: prem(85);
    }
}

.pay-2-box {
    margin-top: prem(90);
    display: flex;

    .left, .right {
        flex: 1;
    }

    .left-box {
        width: prem(518);
        margin: 0 auto;
    }
}

.pay-header-text {
    color: $reg-pay-text-color-1;
    font-size: prem(32);
    position: absolute;
    right: prem(331);
    top: prem(134);
}

.pay-2t1 {
    color: $reg-pay-sub-text-color;
    font-size: prem(17);
}

.pay-2t2 {
    color: $reg-pay-text-color-1;
    font-size: prem(24);
    margin-top: prem(15);
    font-weight: bold;
    line-height: 2em;
}

.pay-2t3 {
    color: $reg-pay-text-color-1;
    font-size: prem(18);
    margin-top: prem(30);

    > div {
        margin-bottom: prem(11);

        div {
            margin-top: prem(11);
            padding-left: prem(20);
        }
    }
}

.pay-2t4 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: prem(60);
    flex-direction: column;

    .tt1 {
        font-size: prem(15);
        margin-top: prem(14);
        color: $reg-pay-text-color-1;
    }
}

.reg-btn {
    width: prem(257);
    height: prem(57);
    font-size: prem(22);
    background-color: #54bcc5;
    font-weight: bold;
    text-align: center;
    line-height: prem(57);
    color: white;
    cursor: pointer;

    &.disabled {
        background-color: #dbdbdb;
        cursor: auto;
    }
}

.pt-image {
    width: prem(306);
    height: prem(403);
    background-color: #dbdbdb;
    overflow: hidden;

    img{
        width: 100%;
        height: 100%;
    }
}

.pt-info {
    display: flex;
    align-items: center;
}

.pt-summary {
    margin-left: prem(123);
    flex: 1;

}

.pt-summary-title {
    color: $reg-pay-text-color-1;
    font-size: prem(24);
    font-weight: bold;
}

.pt-summary-content {
    font-size: prem(18);
    margin-top: prem(30);
    line-height: prem(40);
    white-space: break-spaces;
}

.contact-info {
    display: flex;
}

.pt-phone {
    width: prem(306);
}

.pt-qrcode {
    margin-left: prem(123);
}

.pt-phone {
    margin-top: prem(57);
    font-size: prem(22);

    > div:first-child {
        margin-bottom: prem(18);
    }
}

.pt-qrcode {
    margin-top: prem(26);
    text-align: center;
}

.pt-qrcode-image {
    width: prem(117);
    height: prem(117);
    background-color: #dbdbdb;
    margin-bottom: prem(10);
    img{
        width: 100%;
        height: 100%;
    }
}

.sp1 {
    margin-right: prem(40);
}
</style>