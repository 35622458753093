<template>
    <div class="reg-box">
        <div class="reg-title">
            <div class="reg-ch-title">微信支付申请</div>
            <div class="reg-en-title">WeChat payment application</div>
        </div>
        <div class="ast-reg-steps">
            <div class="ast-reg-step active">1</div>
            <div class="ast-reg-step active">2</div>
            <div class="ast-reg-step active">3</div>
            <div class="ast-reg-step active">4</div>
            <div class="ast-reg-step active">5</div>
        </div>
        <div class="pay-box">
            <pay-index :customer-type="payCustomerType"/>
        </div>
    </div>
</template>

<script>

import PayIndex from "@/views/AST/Pay/Index.vue";

export default {
    name: "Pay",
    props: {
        companyInfo: Object
    },
    components: {
        PayIndex
    },
    data() {
        return {
            customerType: "",
            payCustomerType: "",
        }
    },
    methods: {

    }
}
</script>

<style scoped lang="less">

.ast-reg-steps {
    margin-bottom: 80px;
}

.pay-box {
    padding-right: 60px;
    margin-bottom: 80px;
}

</style>